enum ActionTypeKeys {
    FETCH_ENTRIES = 'FETCH_ENTRIES',
    FETCH_ENTRIES_PENDING = 'FETCH_ENTRIES_PENDING',
    FETCH_ENTRIES_RESOLVED = 'FETCH_ENTRIES_RESOLVED',
    FETCH_ENTRIES_REJECTED = 'FETCH_ENTRIES_REJECTED',

    CREATE_ENTRY = 'CREATE_ENTRY',
    CREATE_ENTRY_PENDING = 'CREATE_ENTRY_PENDING',
    CREATE_ENTRY_RESOLVED = 'CREATE_ENTRY_RESOLVED',
    CREATE_ENTRY_REJECTED = 'CREATE_ENTRY_REJECTED',

    FETCH_ENTRY = 'FETCH_ENTRY',
    FETCH_ENTRY_PENDING = 'FETCH_ENTRY_PENDING',
    FETCH_ENTRY_RESOLVED = 'FETCH_ENTRY_RESOLVED',
    FETCH_ENTRY_REJECTED = 'FETCH_ENTRY_REJECTED',

    DELETE_ENTRY = 'DELETE_ENTRY',
    DELETE_ENTRY_PENDING = 'DELETE_ENTRY_PENDING',
    DELETE_ENTRY_RESOLVED = 'DELETE_ENTRY_RESOLVED',
    DELETE_ENTRY_REJECTED = 'DELETE_ENTRY_REJECTED',

    FETCH_USERS = 'FETCH_USERS',
    FETCH_USERS_PENDING = 'FETCH_USERS_PENDING',
    FETCH_USERS_RESOLVED = 'FETCH_USERS_RESOLVED',
    FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED',

    CREATE_USER = 'CREATE_USER',
    CREATE_USER_PENDING = 'CREATE_USER_PENDING',
    CREATE_USER_RESOLVED = 'CREATE_USER_RESOLVED',
    CREATE_USER_REJECTED = 'CREATE_USER_REJECTED',

    FETCH_USER = 'FETCH_USER',
    FETCH_USER_PENDING = 'FETCH_USER_PENDING',
    FETCH_USER_RESOLVED = 'FETCH_USER_RESOLVED',
    FETCH_USER_REJECTED = 'FETCH_USER_REJECTED',

    DELETE_USER = 'DELETE_USER',
    DELETE_USER_PENDING = 'DELETE_USER_PENDING',
    DELETE_USER_RESOLVED = 'DELETE_USER_RESOLVED',
    DELETE_USER_REJECTED = 'DELETE_USER_REJECTED',

    SIGN_IN = 'SIGN_IN',
    SIGN_IN_PENDING = 'SIGN_IN_PENDING',
    SIGN_IN_RESOLVED = 'SIGN_IN_RESOLVED',
    SIGN_IN_REJECTED = 'SIGN_IN_REJECTED',

    SIGN_OUT = 'SIGN_OUT',
    SIGN_OUT_PENDING = 'SIGN_OUT_PENDING',
    SIGN_OUT_RESOLVED = 'SIGN_OUT_RESOLVED',
    SIGN_OUT_REJECTED = 'SIGN_OUT_REJECTED'
}

export default ActionTypeKeys;